<template>
  <div class="text-left">
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else class="pt-4 mt-4">
      <!-- {{ balanceTotals }} -->
      <div
        class="row"
        v-if="balanceTotals && Object.keys(balanceTotals).length > 0"
      >
        <div
          class="col-12 col-sm-4"
          v-for="(item, index) in Object.keys(balanceTotals)"
          :key="`${index}pts`"
        >
          <div class="card mb-0 text-white" :class="'bg-'+getColor(item)">
            <div class="card-body">
              <div class="d-flex">
                <h3 class="font-weight-semibold mb-0 text-capitalize">
                  $ {{ balanceTotals[item] || 0.0 }}
                </h3>
                <span
                  class="badge badge-dark badge-pill align-self-center ml-auto"
                  >USD</span
                >
              </div>

              <div>
                <span class="text-capitalize">{{ item.replace("_", " ") || "Totals" }}</span>
                <div class="font-size-sm opacity-75">USD</div>
              </div>
            </div>
          </div>

          <!-- <v-alert
            class="rounded"
            :color="getColor(item)"
            prominent
            dense
            text
            :type="getColor(item)"
          >
            <h2 class="font-weight-bold">$ {{ balanceTotals[item] || 0.0 }}</h2>
            <span class="text-capitalize">
              {{ item.replace("_", " ") || "Totals" }}
            </span>
          </v-alert> -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Payments",
  computed: {
    ...mapState("payment", [
      "balanceTotals",
      "balancePayments",
      "loading",
      "balancePaymentsHeaders",
    ]),
  },

  methods: {
    ...mapActions("payment", ["_getBalancePayments"]),
    getColor(status) {
      let color = "success";
      if (status.includes("pending")) {
        color = "warning";
      } else if (status.includes("earned")) {
        color = "teal";
      } else if (status.includes("fines")) {
        color = "pink";
      } else if (status.includes("available")) {
        color = "info";
      } else {
        color = 'primary'
      }
      return color;
    },
  },

  mounted() {
    this._getBalancePayments().catch((err) =>
      console.log("error fetching payments", err)
    );
  },
};
</script>

<style scoped>
.v-alert {
  min-height: 120px;
  max-height: 120px;
  border-radius: 20px;
}
</style>
